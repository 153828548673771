import React from "react";
import "./Talks.scss";
import {talkSection} from "../../portfolio";
import {Fade} from "react-reveal";

export default function Talks() {
  if (!talkSection.display) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="about-me">
        <div className="about-header">
          <h1 className="about-header-title">{talkSection.title}</h1>
          <p className="about-header-paragraph">
            Besides tech, I love movies, hiking, and any artistic hobby.
            <br /> 
            <br />  
            Dance is something that I've been a part of ever since I was two and I've been able to continue my love for the sport through college, by being a part of the Pitt Dance Team. Now, I spend my free time teaching and coaching dance!
            <br /> 
            <br /> 
            I love Disney and their films were my inspiration behind getting my minor in Film!
            <br /> 
            <br /> 
            I also love to hike and have set a goal to visit all 63 National Parks!
          </p>
          <div className="about-images">
            {talkSection.images.map((image, i) => (
              <img key={i} src={image.url} alt={image.alt} className="about-image" />
            ))}
          </div>
        </div>
      </div>
    </Fade>
  );
}
